@import './partials/authorization';
.auth__page {
  padding: 100px 0;
  position: relative;
  .container {
    position: relative;
    z-index: 2;
  }
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: url('../img/backgrounds/lc-bg.png') no-repeat bottom left;
    background-size: auto 360px;
    background-position: 0 100%;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    opacity: 0.3;
  }
}

@media (max-width: 576px) {
  .auth__page {
    padding: 50px 0;
  }
}